bovoApp.config(['$routeProvider', '$locationProvider', '$httpProvider',
    function ($routeProvider, $locationProvider, $httpProvider) {
        $routeProvider
            .when('/login', { title: 'Giriş Yap', templateUrl: 'modules/auth/login.html' })
            .when('/register', { title: 'Register', templateUrl: 'modules/auth/register.html' })
            .when('/kayit-tamamlandi', { title: 'Kayıt Tamamlandı', templateUrl: 'modules/auth/register-success.html' })
            .when('/forgot', { title: 'Şifremi Unuttum', templateUrl: 'modules/auth/forgot.html' })
            .when('/sifre-sifirla?:data?', { title: 'Şifre Sıfırla', templateUrl: 'modules/auth/password.html' })

            /** Maps */
            .when('/dashboard', { title: 'Dashboard', templateUrl: 'modules/company/analytics/dashboard.html', resolve: reports })
            .when('/support', { title: 'Destek', templateUrl: 'modules/company/dashboard/support.html', resolve: reports })
            .when('/mng-iade', { title: 'Mng Iade Talepleri', templateUrl: 'modules/company/cargo/mng/fail.html', resolve: maps })

            .when('/order-report', { title: 'Kargo Raporları', templateUrl: 'modules/company/analytics/orders.html', resolve: reports })
            .when('/order-activity?:filter', { title: 'Kargo Hareketleri', templateUrl: 'modules/company/dashboard/notification.html', resolve: maps })
            .when('/order-move?:filter', { title: 'Kargo Hareketleri', templateUrl: 'modules/company/movement/move.html', resolve: maps })
            .when('/order-debit-list?:filter', { title: 'Kargo Sorumluluk Listesi', templateUrl: 'modules/company/movement/list.html', resolve: maps })

            .when('/order-bulk-complete?:filter', { title: 'Toplu Kargo Hareketi', templateUrl: 'modules/company/movement/bulk-complete.html', resolve: maps })
            .when('/hepsijet-recall?:filter', { title: 'Toplu Kargo Hareketi', templateUrl: 'modules/company/movement/bulk-complete.html', resolve: maps })

            .when('/order-warnings?:filter', { title: 'Kargo Uyarıları', templateUrl: 'modules/company/order-warning/list.html', resolve: maps })

            .when('/order-invoices?:filter', { title: 'Kargo Faturaları', templateUrl: 'modules/company/dashboard/order_invoices.html', resolve: maps })
            .when('/third-party-decisions', { title: 'Kargo Firmaları Karar Tablosu', templateUrl: 'modules/company/dashboard/third_party_decision.html', resolve: maps })
            .when('/off-zones', { title: 'AT Tablosu', templateUrl: 'modules/company/off-zone/list.html', resolve: maps })

            .when('/fail-calls?:filter', { title: 'Devir Aramaları', templateUrl: 'modules/company/fail-calls/list.html', resolve: maps })

            .when('/fail-orders?:filter', { title: 'Teslim Edilmeyen Kargo Listesi', templateUrl: 'modules/company/fail-order/list.html', resolve: maps })
            .when('/fail-order/create', { title: 'Teslim Edilmeyen Kargo Girişi', templateUrl: 'modules/company/fail-order/create.html', resolve: maps })
            .when('/fail-order/approve', { title: 'Teslim Edilmeyen Kargo Onayı', templateUrl: 'modules/company/fail-order/approve.html', resolve: maps })

            .when('/waiting-orders', { title: 'Eski Kargolar', templateUrl: 'modules/company/old-order/list.html', resolve: reports })
            .when('/door-pay-orders', { title: 'Kapıda Ödeme Kargolar', templateUrl: 'modules/company/door-pay-order/list.html', resolve: reports })
            .when('/notes-orders', { title: 'Notu Olan Kargolar', templateUrl: 'modules/company/notes-order/list.html', resolve: reports })

            .when('/return-orders?:filter', { title: 'İade Kargo Listesi', templateUrl: 'modules/company/return-order/list.html', resolve: maps })
            .when('/return-order/create', { title: 'İade Kargo Girişi', templateUrl: 'modules/company/return-order/create.html', resolve: maps })

            // .when('/', {title: 'Özet ', templateUrl: 'modules/company/dashboard/dashboard.html'})
            .when('/map', { title: 'Harita ', templateUrl: 'modules/company/dashboard/map.html', resolve: maps })
            .when('/routing', { title: 'Rotalar ', templateUrl: 'modules/company/routing/index.html', resolve: maps })
            .when('/route-move?:filter', { title: 'Rota Kargo Tanımlama', templateUrl: 'modules/company/routes/move.html', resolve: maps })

            .when('/', { title: 'Kargo Listesi', templateUrl: 'modules/company/dashboard/list.html', resolve: maps })
            .when('/orders?:filter', { title: 'Kargo Listesi', templateUrl: 'modules/company/dashboard/list.html', resolve: maps })
            .when('/takvim?:filter', { title: 'Kargo Listesi', templateUrl: 'modules/company/dashboard/calendar.html', resolve: maps })
            .when('/new-order?', { title: 'Yeni Kargo', templateUrl: 'modules/company/dashboard/new_order.html', resolve: customer })

            .when('/destek?:filter', { title: 'Kargo Listesi', templateUrl: 'modules/company/cargo/support.html', resolve: maps })

            .when('/ozel-alanlar', { title: 'Kargo Özel Alan Yönetimi', templateUrl: 'modules/company/order-field/list.html', resolve: maps })
            .when('/ozel-alanlar/yeni', { title: 'Yeni Kargo Özel Alanı', templateUrl: 'modules/company/order-field/new.html', resolve: maps })
            .when('/ozel-alanlar/:id', { title: 'Kargo Özel Alanı', templateUrl: 'modules/company/order-field/edit.html', resolve: maps })

            .when('/stations', { title: 'Şubeler', templateUrl: 'modules/company/stations/list.html', resolve: maps })
            .when('/area-management', { title: 'Bölge Müdürlükleri', templateUrl: 'modules/company/stations/list.html', resolve: maps })

            .when('/transfer-centers', { title: 'Transfer Merkezleri', templateUrl: 'modules/company/transfers/list.html', resolve: maps })
            .when('/shipment-areas', { title: 'Dağıtım Bölgeleri', templateUrl: 'modules/company/shipment/list.html', resolve: maps })
            .when('/line-vehicles', { title: 'Ring Araçları', templateUrl: 'modules/company/line-vehicles/list.html', resolve: maps })
            // .when('/route-vehicles', { title: 'Sefer Araçları', templateUrl: 'modules/company/line-vehicles/list.html', resolve: maps })
            // .when('/vehicle-routes', { title: 'Güzergahlar', templateUrl: 'modules/company/vehicle-routes/list.html', resolve: maps })
            .when('/transfer-reasons', { title: 'Devir Sebepleri', templateUrl: 'modules/company/transfer-reasons/list.html', resolve: maps })
            .when('/redirect-reasons', { title: 'Yönlendirme Sebepleri', templateUrl: 'modules/company/transfer-reasons/list.html', resolve: maps })
            .when('/completed-reasons', { title: 'Tamamlanma Sebepleri', templateUrl: 'modules/company/transfer-reasons/list.html', resolve: maps })
            .when('/holidays', { title: 'Tatil Günleri', templateUrl: 'modules/company/holidays/list.html', resolve: maps })
            .when('/package-types', { title: 'Paket Türleri', templateUrl: 'modules/company/package-types/list.html', resolve: maps })
            .when('/pallettes', { title: 'Palet Yönetimi', templateUrl: 'modules/company/pallettes/list.html', resolve: customers })

            .when('/announcements', { title: 'Duyuru & Güncellemeler', templateUrl: 'modules/company/announcements/list.html', resolve: maps })

            .when('/voyages', { title: 'Seferler', templateUrl: 'modules/company/voyages/list.html', resolve: maps })
            .when('/new-voyage', { title: 'Yeni Seferler', templateUrl: 'modules/company/voyages/new.html', resolve: maps })
            .when('/voyages/:id', { title: 'Sefer Kargo Okut', templateUrl: 'modules/company/voyages/read.html', resolve: maps })

            .when('/performance', { title: 'Saf Performans', templateUrl: 'modules/company/performance/common.html', resolve: maps })

            .when('/offers', { title: 'Offers', templateUrl: 'modules/company/offers/list.html', resolve: maps })
            .when('/offers/new', { title: 'New Offer', templateUrl: 'modules/company/offers/new.html', resolve: maps })
            .when('/offers/:id', { title: 'Offer', templateUrl: 'modules/company/offers/edit.html', resolve: maps })

            .when('/products', { title: 'Ürünler', templateUrl: 'modules/company/products/list.html', resolve: maps })

            .when('/chats', { title: 'Sohbet', templateUrl: 'modules/company/chat/index.html', resolve: maps })

            .when('/invoice', { title: 'Faturalar', templateUrl: 'modules/company/billing/list.html', resolve: maps })
            .when('/collection', { title: 'Kapıdan Odemeler', templateUrl: 'modules/company/collection/list.html', resolve: maps })
            .when('/billing/new', { title: 'New Invoice', templateUrl: 'modules/company/billing/new.html', resolve: maps })
            .when('/billing/:id', { title: 'Invoice', templateUrl: 'modules/company/billing/edit.html', resolve: maps })

            .when('/earning', { title: 'Odemeler', templateUrl: 'modules/company/earning/list.html', resolve: maps })
            .when('/earning/new', { title: 'New Earning', templateUrl: 'modules/company/earning/new.html', resolve: maps })
            .when('/earning/:id', { title: 'Earning', templateUrl: 'modules/company/earning/edit.html', resolve: maps })

            .when('/routes', { title: 'Rotalar', templateUrl: 'modules/company/routes/list.html', resolve: maps })
            .when('/routes/new', { title: 'New Route', templateUrl: 'modules/company/routes/new.html', resolve: maps })
            .when('/routes/:id', { title: 'Route', templateUrl: 'modules/company/routes/edit.html', resolve: maps })
            .when('/route-activity?:filter', { title: 'Rota Hareketleri', templateUrl: 'modules/company/routes/activity.html', resolve: maps })
            .when('/waiting-routes', { title: 'Açık Rotalar', templateUrl: 'modules/company/routes/old_route.html', resolve: maps })

            .when('/is-tipleri', { title: 'Kargo Tipleri', templateUrl: 'modules/company/work-types/all.html', resolve: maps })
            .when('/is-tipleri/yeni', { title: 'Yeni Kargo Tipi', templateUrl: 'modules/company/work-types/new.html', resolve: maps })
            .when('/is-tipleri/:id', { title: 'Kargo Tipi', templateUrl: 'modules/company/work-types/edit.html', resolve: maps })

            .when('/formlar', { title: 'Formlar', templateUrl: 'modules/company/forms/list.html', resolve: maps })
            .when('/form/yeni', { title: 'Yeni Form', templateUrl: 'modules/company/forms/new.html', resolve: maps })
            .when('/form/:id', { title: 'Form', templateUrl: 'modules/company/forms/edit.html', resolve: maps })

            // .when('/statuses', { title: 'Kargo Durumları', templateUrl: 'modules/company/work-steps/all.html', resolve: maps })
            .when('/mesajlar', { title: 'Sms Mesajları', templateUrl: 'modules/company/sms/index.html', resolve: maps })

            .when('/checklist-sablonlar', { title: 'Checklist Şablon Yönetimi', templateUrl: 'modules/company/checklist-template/list.html', resolve: maps })
            .when('/checklist-sablonlar/yeni', { title: 'Yeni Checklist Şablonu', templateUrl: 'modules/company/checklist-template/new.html', resolve: maps })
            .when('/checklist-sablonlar/:id', { title: 'Checklist Şablonu', templateUrl: 'modules/company/checklist-template/edit.html', resolve: maps })

            .when('/ayarlar', { title: 'Ayarlar', templateUrl: 'modules/company/settings/plugins.html', resolve: maps })
            .when('/delivery-zone', { title: 'Dağıtım Bölgeleri', templateUrl: 'modules/company/settings/delivery-zone.html', resolve: maps })

            /** Office */
            .when('/projeler', { title: 'Kargo Takip ', templateUrl: 'modules/company/tasks/projects.html', resolve: office })
            .when('/proje/:id', { title: 'Kargo Takip ', templateUrl: 'modules/company/tasks/task-list.html', resolve: office })

            /** Reports */
            .when('/raporlama', { title: 'Raporlama', templateUrl: 'modules/company/analytics/index.html', resolve: reports })
            .when('/invoice-reports', { title: 'Fatura Raporları', templateUrl: 'modules/company/analytics/invoice.html', resolve: reports })
            .when('/collection-reports', { title: 'KO Raporları', templateUrl: 'modules/company/analytics/collection.html', resolve: reports })
            .when('/earning-reports', { title: 'Earning Reports', templateUrl: 'modules/company/analytics/earning.html', resolve: reports })
            .when('/partner-report', { title: 'Müşteri Raporları', templateUrl: 'modules/company/analytics/customers.html', resolve: reports })
            .when('/gelir-gider', { title: 'Gelir Gider Raporları', templateUrl: 'modules/company/analytics/profit.html', resolve: reports })
            .when('/iade-teslim', { title: 'Iade Teslim Formu', templateUrl: 'modules/company/analytics/fail.html', resolve: reports })
            .when('/form-raporlari', { title: 'Müşteri Form Raporları', templateUrl: 'modules/company/analytics/forms.html', resolve: reports })
            .when('/driver-reports', { title: 'Personel Raporları', templateUrl: 'modules/company/analytics/workers.html', resolve: reports })

            /** Customers */
            .when('/musteri-yonetimi', { title: 'Müşteri Yönetimi', templateUrl: 'modules/company/customers/all.html', resolve: customers })
            .when('/musteri-yonetimi-map', { title: 'Müşteri Yönetimi', templateUrl: 'modules/company/customers/map.html', resolve: customers })
            .when('/pasif-musteri', { title: 'Müşteri Yönetimi', templateUrl: 'modules/company/customers/passive.html', resolve: customers })

            .when('/musteri-yonetimi/ozel-alanlar', { title: 'Müşteri Özel Alan Yönetimi', templateUrl: 'modules/company/customer-field/list.html', resolve: customers })
            .when('/musteri-yonetimi/ozel-alanlar/yeni', { title: 'Yeni Müşteri Özel Alanı', templateUrl: 'modules/company/customer-field/new.html', resolve: customers })
            .when('/musteri-yonetimi/ozel-alanlar/:id', { title: 'Müşteri Özel Alanı', templateUrl: 'modules/company/customer-field/edit.html', resolve: customers })
            .when('/customer-group', { title: 'Müşteri Grupları', templateUrl: 'modules/company/customer-group/list.html', resolve: customers })
            .when('/price-list', { title: 'Fiyat Listesi', templateUrl: 'modules/company/price-list/list.html', resolve: customers })
            
            .when('/pricing', { title: 'Fiyat Listesi', templateUrl: 'modules/company/pricing/list.html', resolve: customers })
            .when('/pricing-cities', { title: 'Fiyatlama Çıkış Varış İlleri', templateUrl: 'modules/company/pricing-city/list.html', resolve: customers })
            .when('/pricing-assign', { title: 'Fiyatlama Ataması', templateUrl: 'modules/company/pricing-assign/list.html', resolve: customers })

            .when('/allocation', { title: 'Allokasyon Listesi', templateUrl: 'modules/company/allocation/list.html', resolve: customers })
            .when('/allocation-assign', { title: 'Allokasyon Ataması', templateUrl: 'modules/company/allocation-assign/list.html', resolve: customers })
            .when('/allocation-control', { title: 'Allokasyon Kontrol', templateUrl: 'modules/company/allocation-control/list.html', resolve: customers })

            /** Users */
            .when('/calisan-yonetimi', { title: 'Çalışan Yönetimi', templateUrl: 'modules/company/workers/list.html', resolve: users })
            .when('/calisan-yonetimi/detay/:id', { title: 'Çalışan Detayı', templateUrl: 'modules/company/workers/show.html', resolve: users })
            .when('/calisan-yonetimi/detay/:id/duzenle', { title: 'Çalışan Düzenle', templateUrl: 'modules/company/workers/edit.html', resolve: users })
            .when('/yeni-calisan', { title: 'Yeni Çalışan', templateUrl: 'modules/company/workers/new.html', resolve: users })

            .when('/ekip-yonetimi', { title: 'Ekip Yönetimi', templateUrl: 'modules/company/team/all.html', resolve: users })
            .when('/ekip-yonetimi/detay/:id', { title: 'Ekip Detayı', templateUrl: 'modules/company/team/show.html', resolve: users })
            .when('/yeni-ekip', { title: 'Yeni Ekip', templateUrl: 'modules/company/team/new.html', resolve: users })

            .when('/users', { title: 'Yönetici', templateUrl: 'modules/company/users/all.html', resolve: users })

            /** Settings */
            .when('/odeme-bilgileri?:filter', { title: 'Ödeme Bilgileri', templateUrl: 'modules/company/settings/payments.html', resolve: settings })
            .when('/hesap-bilgileri:filter?', { title: 'Hesap Bilgileri', templateUrl: 'modules/company/package/index.html', resolve: settings })

            .when('/profile', { title: 'Şirket Bilgileri', templateUrl: 'modules/company/settings/company.html', resolve: settings })
            .when('/change-password', { title: 'Şifre Değiştir', templateUrl: 'modules/company/settings/password.html', resolve: settings })
            .when('/sms-entegrasyonu', { title: 'Sms Sağlayıcı Entegrasyonu', templateUrl: 'modules/company/settings/sms_provider.html', resolve: settings })
            .when('/webhook', { title: 'WEBHOOK', templateUrl: 'modules/company/settings/webhook.html', resolve: settings })

            /* Day End */
            .when('/gun-sonu', { title: 'Gün Sonu Yönetimi', templateUrl: 'modules/company/dayend/list.html', resolve: maps })
            .when('/gun-sonu/yeni', { title: 'Yeni Gün Sonu', templateUrl: 'modules/company/dayend/new.html', resolve: maps })
            .when('/gun-sonu/:id', { title: 'Gün Sonu', templateUrl: 'modules/company/dayend/edit.html', resolve: maps })

            /** Stock Systems */

            /** Field Projects*/
            .when('/stok/projeler', { title: 'Proje Yönetimi', templateUrl: 'modules/company/field-projects/list.html', resolve: stocks })
            .when('/stok/projeler/yeni', { title: 'Yeni Proje', templateUrl: 'modules/company/field-projects/new.html', resolve: stocks })
            .when('/stok/projeler/:id', { title: 'Proje', templateUrl: 'modules/company/field-projects/edit.html', resolve: stocks })

            .when('/stok/depolar', { title: 'Depo Yönetimi', templateUrl: 'modules/company/stock-store/list.html', resolve: stocks })
            .when('/stok/depo/yeni', { title: 'Yeni Depo', templateUrl: 'modules/company/stock-store/new.html', resolve: stocks })
            .when('/stok/depo/:id', { title: 'Depo', templateUrl: 'modules/company/stock-store/edit.html', resolve: stocks })

            .when('/stok/urunler', { title: 'Stok Yönetimi', templateUrl: 'modules/company/stock/list.html', resolve: stocks })
            .when('/stok/urun/yeni', { title: 'Yeni Stok', templateUrl: 'modules/company/stock/new.html', resolve: stocks })
            .when('/stok/urun/:id', { title: 'Stok', templateUrl: 'modules/company/stock/edit.html', resolve: stocks })

            .when('/stok/hareketler', { title: 'Stok Hareketleri', templateUrl: 'modules/company/stock-movement/list.html', resolve: stocks })
            .when('/stok/hareket/yeni', { title: 'Yeni Stok Hareketi', templateUrl: 'modules/company/stock-movement/new.html', resolve: stocks })
            .when('/stok/hareket/:id', { title: 'Stok Hareketi', templateUrl: 'modules/company/stock-movement/edit.html', resolve: stocks })
            
            .when('/integration-errors', { title: 'Entegrasyon Hataları', templateUrl: 'modules/company/orders/integration-errors.html', resolve: stocks })
            .when('/poll-results', { title: 'Kargo Değerlendirme Sonuçları', templateUrl: 'modules/company/orders/poll-results.html', resolve: stocks })
            
            //Customer
            .when('/customer-new-order?', { title: 'Yeni Kargo', templateUrl: 'modules/customer/new_order.html', resolve: customer })
            .when('/customer-orders', { title: 'Kargo Listesi', templateUrl: 'modules/customer/orders.html', resolve: customer })
            .when('/customer-users', { title: 'Kullanıcılar', templateUrl: 'modules/customer/users/all.html', resolve: customer })
            .when('/customer-dashboard', { title: 'Raporlar', templateUrl: 'modules/customer/customer_dashboard.html', resolve: customer })
            .when('/customer-account', { title: 'Hesap', templateUrl: 'modules/customer/account.html', resolve: customer })
            .when('/customer-settings', { title: 'Ayarlar', templateUrl: 'modules/customer/settings.html', resolve: customer })
            .when('/customer-password', { title: 'Şifre Değiştir', templateUrl: 'modules/customer/password.html', resolve: customer })
            .when('/customer-tracking', { title: 'Kargo Takip', templateUrl: 'modules/customer/tracking.html', resolve: customer })
            .when('/customer-accounting', { title: 'Muhasebe', templateUrl: 'modules/customer/accounting.html', resolve: customer })
            .when('/customer-billing', { title: 'Faturalar', templateUrl: 'modules/customer/billing.html', resolve: customer })
            .when('/customer-feedback', { title: 'Geri Bildirim', templateUrl: 'modules/customer/feedback.html', resolve: customer })
            .when('/customer-check-orders', { title: 'Check Orders', templateUrl: 'modules/customer/check_orders.html', resolve: customer })
            .when('/customer-notifications', { title: 'Bildirimler', templateUrl: 'modules/customer/notification.html', resolve: customer })
            .when('/customer-support', { title: 'Destek', templateUrl: 'modules/customer/support.html', resolve: customer })
            .when('/customer-consensus', { title: 'Mutabakat Listesi', templateUrl: 'modules/customer/consensus.html', resolve: customer })

            .when('/satin-al:filter?', { title: 'Satın Al', templateUrl: 'modules/customer/package/index.html', resolve: settings })
            .otherwise({ redirectTo: '/' });

        $locationProvider.html5Mode(true);
        $httpProvider.interceptors.push(['$rootScope', '$q', '$localStorage',

            function ($rootScope, $q, $localStorage) {
                $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

                return {
                    'responseError': function (response) {
                        if (response.status === 401) {
                            // $rootScope.$broadcast('unauthorized');
                            // window.location = '/login';
                        } else if (response.status === 400) {
                            //window.location = '/';
                            $rootScope.$broadcast('unauthorized');
                            window.location = '/login';
                        } else if (response.status === 404) {
                            //window.location = '/';
                        } else if (response.status === 403) {
                            window.location = '/hesap-bilgileri';
                        }
                        return $q.reject(response);
                    }
                };
            }
        ]);
    }
]);

bovoApp.run(['$rootScope', '$location', function ($rootScope, $location) {

    $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
        console.log(rejection);
        if (rejection === 'Unauthorized') {
            $('#notPermission').modal('show');
        }
    });
}])