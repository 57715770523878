bovoApp.config(['$translateProvider', function($translateProvider) {
    $translateProvider.translations('tr', {
        // login.html
        'LOGIN_TITLE': 'Giriş Yap',
        'EMAIL_LOGIN_PLCHLDR': 'E-Posta Adresiniz...',
        'PASS_LOGIN_PLCHLDR': 'Şifreniz...',
        'REMEMBER_ME': 'Beni Hatırla',
        'LOGIN': 'Giriş Yap',

        // forgot.html
        'FORGOT_PASS': 'Şifremi Unuttum',
        'PASS_RESET_TITLE': 'Şifremi Sıfırla',
        'PASS_RESET_CONTENT': 'Lütfen giriş yaparken kullandığınız mail adresiniz giriniz.',
        'RESET_PASS_BTN': 'Sıfırla',

        // password.html
        'PASSWORD_TITLE': 'Yeni Şifre',
        'PASSWORD_TITLE_BTN': 'Yeni Şifre Talep Et',
        'PASS_REPEAT_PLCHLDR': 'Şifre Tekrar...',
        'PASS_FORM_BTN': 'Sıfırla',

        'MAP': 'Harita',
        'ROUTES': 'Rotalar',
        'ORDERS': 'Kargolar',
        'OFFERS': 'Teklifler',
        'PRODUCTS': 'Ürünler',
        'SUPPORT': 'Destek',
        'ORDER_WARNINGS': 'Uyarılar',
        'DRIVERS': 'Kuryeler',
        'EARNING': 'Odemeler',
        'PARTNERS': 'Müşteriler',
        'INVOICES': 'Faturalar',
        'STATIONS': 'Şubeler',
        'REPORTS': 'Raporlar',
        'SETTINGS': 'Ayarlar',
        'LOGOUT': 'Çıkış Yap',
        'BILLING': 'Faturalar',
        'FEEDBACK': 'Geri Bildirim',
        'ACCOUNT_INACTIVE': 'Hesabını aktif değildir.',
        'ACCOUNT_INCORRECT_INFO': 'Bilgileriniz hatalıdır.',

        'HEPSIJET_RECALL': 'Hepsijet ReCall',
        
        'SET_TICIMAX_ID': 'Ticimax Kargo Kodu Ata',

        'PARTNER': 'Müşteri',
        'SELECT_PARTNER': 'Müşteri Seç',
        'DELIVERY_TYPE': 'Gönderi Tipi',
        'DELIVERY_RETURN_TYPE': 'İade Tipi',
        'PARTNER_NAME': 'Müşteri Adı',
        'PARTNER_DETAIL': 'Müşteri Detayları',
        'PARTNER_REPORT': 'Müşteri Raporları',
        'NEW_PARTNER': 'Yeni Müşteri',
        'PARTNER_NOT_FOUND': 'Müşteri Bulunamadı',
        'CUSTOMER_GROUP': 'Müşteri Grupları',
        'SELECT_CUSTOMER_GROUP': 'Müşteri Grubu',
        'CUSTOMER_GROUP_': 'Müşteri Grubu',
        "PASSIVE_CUSTOMER": "Pasif Müşteriler",
        'CUSTOMER_GROUP_NOT_FOUND': 'Müşteri Grubu Bulunamadı',
        'NEW_CUSTOMER_GROUP': 'Yeni müşteri grubu',
        'EDIT_CUSTOMER_GROUP': 'Müşteri Grubu Düzenle',
        'COMPANY_CUSTOMER': 'Müşteri',
        'COMPANY_CUSTOMERS': 'Müşteriler',


        'NEW_PRICING_CITY': 'Yeni Çıkış İl Grubu',
        'EDIT_PRICING_CITY': 'Çıkış İl Grubu Düzenle',
        'PRICING_CITIES': 'Fiyat Listesi - İl Grubu',
        'SELECT_EXIT_CITY': 'Çıkış İli Seçiniz',
        'SELECT_ARRIVAL_CITY': 'Varış İli Seçiniz',

        'EXIT_CITY': 'Çıkış İli',
        'ARRIVAL_CITY': 'Varış İli',
        'CAT_NAME': 'Kategori Adı',

        'REMOVE': 'Kaldır',
        'REMOVE_CUSTOMER': 'Kaldır',
        'ADD_CUSTOMER': 'Müşteri Ekle',
        'SELECT_USER': 'Kullanıcı Seçin',
        'USER': 'Kullanıcı',
        'PROCESS_USER': 'İşlem Yapan Kullanıcı',

        'ORDER_ID': 'Kargo No',
        'PRODUCT_DETAILS': 'Ürün Detayları',
        'FULL_NAME': 'Adı',
        'PHONE': 'Telefon Numarası',
        'STREET_ADDRESS': 'Açık Adres',
        'STREET_ADDRESS_2': 'Adres Ek',
        'APARTMENT_BUILDIN': 'Bina No, Daire No',
        'SPECIAL_INST': 'Ek Açıklamalar',
        'DELIVERY_DATE': 'Taşıma Tarihi',
        'WEIGHT': 'Desi',
        'PIECE': 'Parça',
        'ADD_MORE': 'Paket Ekle',
        'REMOVE_BOX': 'Paketi Kaldır',
        'CREATE_AND_PRINT': 'Kaydet ve Yazdır',
        "PRINT_ORDER": "Kargo Yazdır",
        'IMPORT_ORDER_EXCEL': 'Excel ile Kargo Yükle',
        'IMPORT_EXCEL': 'Excel ile Yükle',
        'IMPORT_INVOICE_EXCEL': 'Excel ile Fatura Yükle',
        'FLEXIBLE_DATE': 'Esnek Tarih',
        'CITY': 'İl',
        'STATE': 'İlçe',
        'SELECT_STATE': 'İlçe Seç',
        'SELECT_CITY': 'İl Seç',
        'SELECT_DISTRICTS': 'İlçe Seç',
        'ADD_DISTRICT': 'İlçe Ekle',
        'REMOVE_DISTRICT': 'Kaldır',
        'ACTIVE_DECISION_CUSTOMERS': 'Karar Listesi Aktif Olan Gruplar',
        'ACTIVE_OFFZONE_CUSTOMERS': 'AT Aktif Olan Gruplar',

        'DOWNLOAD_IMAGE': 'Resmi İndir',

        'CREATED_AT': 'Oluşturulma Tarihi',
        'BY_USER': 'Kullanıcı',
        'CREATED_ORDERS': 'Oluşturulan Kargolar',
        'CREATED': 'Veri Oluşturuldu',
        'ROUTE_CREATED': 'Oluşturuldu',
        'STARTED': 'Başladı',
        'CREATE': 'Oluştur',
        'ORDER_CREATED_SUCCESS': 'Kargo oluşturuldu',
        'COMPLETED_AT': 'Teslim Trh.',
        'LAST_MOVEMENT_AT': 'Son Hrk.',
        'NOTE_FROM_DRIVER': 'Kurye Notu',

        'COMPLETED': 'Tamamlandı',
        'IN_DISTRIBUTION': 'Dağıtımda',
        'WAITING': 'Bekliyor',
        'NUMBER_OF_PIECE': 'Paket Sayısı',

        'ORDER_STATUS': 'Kargo Durumu',
        'ORDER_SUB_STATUS': 'Alt Kargo Durumu',
        'NOTE_NOT_FOUND': 'Not Bulunamadı',
        'WRITE_NOTE': 'Not Ekle',
        'NO_ACTIVITY': 'Hareket Bulunamadı',
        'ROUTE_ACTIVITY': 'Rota Hareketleri',
        'ORDER_ACTIVITY': 'Kargo Hareketleri',
        'ORDER_MOVE': 'Kargo Hareket Girişi',
        'ACTIVITY': 'Hareketler',
        'DOWNLOAD': 'Yükle',
        'UPDOWNLOAD': 'İndir',
        'FILES': 'Belgeler',
        'NOTES': 'Notlar',
        'NO_DETAILS': 'Detay Bulunamadı',
        'DETAILS': 'Detaylar',
        'ORDER_OLD_OPEN': 'Eski Açık Kargolar',
        'AVR_CARGO': 'Ortalama Kargo',
        'ON_DELIVERY_ORDERS': 'Teslimattaki Kargolar',
        'WAIT_ORDERS': 'Yerdeki Kargolar',
        'TOTAL_ORDERS': 'Toplam Kargo',
        'COMPLETED_ORDERS': 'Teslim Edilen Kargolar',
        'REFUND_ORDERS': 'Göndericiye İade Edilen Kargolar',
        'TOTAL_DATA': 'Toplam Veri',
        'AVERAGE_DAILY_SHIPPING': 'Günlük Ortalama Kargo',
        'TOTAL_WORKDAY': 'Toplam Çalışma Günü',
        'SHOW_COMPLETED_ORDERS': 'Teslim Edilen Kargoları Göster',
        'UNSUCCESS_ORDERS': 'Teslim Edilemeyen Kargolar',
        'TRANSFER_ORDERS': 'Devir Kargolar',
        'DELIVERY_TIME': 'Teslimat Tarihi',
        'DELIVERY': 'Teslimat Tarihi',
        'NO_ORDERS': 'Kargo Bulunamadı',
        'NO_ROUTE': 'Rota Bulunamadı',
        'NO_ORDER': 'Kargo Bulunamadı',
        'NO_INVOICE': 'Fatura Bulunamadı',
        'PRINT_ORDERS': 'Kargo(ları) Yazdır',
        'BULK_PRINT': 'Toplu Yazdır',
        'ORDERS': 'Kargolar',
        'APP_STATUS': 'App Durum',
        'WORKER_LAST_ACTION': 'Kurye Son Aktiflik',
        'BY_WORKER': 'Kurye Ara',
        'QUOTA': 'Kota',
        'COMPLETED_REQUIRED_IMAGE': 'Kargo tesliminde resim zorunlu',
        'CONSENSUS_REQUIRED': 'Ring aracına yüklemeyi & Mutabakatı zorunlu tut',
        'CUSTOMER_HIDE_PRICE': 'Müşteri Panelinde Fiyatı Göster',
        'CONSENSUS': 'Mutabakat Listesi',

        'CHANGE_PASSWORD': 'Şifre Değiştir',
        'OLD_PASSWORD': 'Eski Şifre',
        'NEW_PASSWORD': 'Yeni Şifre',
        'COMPANY_INFO': 'Firma Bilgileri',
        'SUBJECT': 'Konu',
        'PRIORTY': 'Oncelik',

        'LOW': 'Düşük',
        'MIDDLE': 'Orta',
        'HIGH': 'Yüksek',
        'SEND': 'Gönder',
        'FEEDBACK_SUCCESSFULL_SEND': 'Geri bildiriminiz alınmıştır, teşekkür ederiz',

        'DRIVER_DETAIL': 'Kurye Detayları',
        'VEHICLE_TYPE': 'Araç Türü',
        'VEHICLE_YEAR': 'Araç Model Yılı',
        'VEHICLE_MAKE': 'Araç Modeli',
        'VEHICLE_MODEL': 'Araç Markası',
        'SELECT_VEHICLE': 'Araç Seç',
        'VEHICLE_INFO': 'Araç Bilgileri',
        'VEHICLE': 'Araç',

        'STOPS': 'Durak Sayısı',
        'ADD_ORDER': 'Kargo Ekle',

        'START_TIME': 'Başlangıç Zamanı',
        'END_TIME': 'Bitiş Zamanı',
        'TOTAL_TIME': 'Toplam Zaman',
        'SERVICE_TIME': 'Servis Süresi',
        'STOP_TIME': 'Servis Süresi',
        'TIME': 'Süre',
        'DISTANCE': 'Mesafe',
        'MILE': 'Km',
        'NOT_ASSIGNED': 'Atanmadı',
        'EDIT_ROUTES': 'Rota Düzenleme Modu',
        'CLOSE_EDIT_ROUTES': 'Düzenleme Modunu Kapat',
        'PLEASE_SELECT_ORDER': 'Lütfen kargo seçiniz.',
        'PCS': 'Adet',

        'DRIVER_NOT_FOUND': 'Kurye Bulunamadı',
        'SELECT_DRIVER': 'Kurye Seç',
        'DRIVER_REPORT': 'Kurye Raporları',
        'ADD_DRIVER': 'Kurye Ekle',
        'ASSIGN_TO_DRIVER': 'Atama Yap',
        'ASSIGN': 'Atama Yap',
        'TRACKING_LINK': 'Takip Linki',
        'EDIT_DRIVER': 'Kuryeyi Düzenle',
        'NOTE_FROM_DRIVER': 'Kurye Notu',
        'DRIVER_INFO': 'Kurye Bilgileri',
        'DRIVER': 'Kurye',
        'ASSIGN_ORDER_RT': 'Kargoyu Rotaya Ata',
        'NOT_ASSIGN': 'Atama Yapılmadı',

        'SELECT_STATUS': 'Durum Seç',
        'CHANGE_STATUS': 'Durum Değiştir',
        'DELIVERY_STATUS': 'Teslim Durumu',
        'ROUTE_STATUS': 'Rota Durumu',
        'CHANGE_ROUTE_STATUS': 'Rota Durumunu Değiştir',
        'ARRIVED_STATUS': 'Varış Durumu',
        'VERIFY_STATUS': 'Okutma Durumu',
        'STATUS': 'Durum',

        'STATION': 'Şube',
        'SELECT_STATION': 'Şube Seç',
        'EDIT_STATION': 'Şube Düzenle',
        'NEW_STATION': 'Yeni Şube',
        'STATION_CODE': 'Birim Kodu',
        'STATION_NOT_FOUND': 'Şube Bulunamadı',
        'STATION_LABEL': 'Birim No',
        'STATION_ADDRESS': 'Şube Adresi',

        // Filters
        'BY_STATION': 'Varış Birimi',
        'WORKER_STATION': 'Sube',
        'START_STATION': 'Çıkış Birimi',
        'UNKNOWN_WORKER': 'Tanımsız Kurye',
        'UNKNOWN_STATION': 'Tanımsız Sube',
        'MAIN_STATION': 'Ana Birim',
        'BY_DRIVER': 'Kurye',
        'BY_DRIVER': 'Kurye',
        'BY_CUSTOMER': 'Alıcı',
        'BY_PARTNER': 'Gönderici',
        'PARTNER_ADDRESS': 'Gönderici Adresi',
        'MAIN_CUSTOMER': 'Ana Cari',
        'SUB_CUSTOMER': 'Alt Cari',
        'BY_PARTNER_GROUP': 'Müşteri Grubu',
        'BY_ORDER_ID': 'Kargo Numarası',
        'BY_ORDER_PAYMENT': 'Tahsilat Türü',
        'BY_DELIVERY_DATE': 'Taşıma Tarihi',
        'BY_STATUS': 'Kargo Durumu',
        'BY_STATUS_WORKER': 'Durum',
        'BY_SUB_STATUS': 'Alt Kargo Durumu',
        'BY_STATUS_NORMAL': 'Durumu',
        'BY_TYPE': 'Kargo Türü',
        'THIRD_TYPE': 'Taşıyıcı Türü',
        'SUB_BY_TYPE': 'Alt Kargo Türü',
        'BY_ACTIVITY_TYPE': 'Hareket Türü',
        'BY_QR_CODE': 'QR Kod',
        'BY_DATE': 'Tarih',
        'BY_PRICE': 'Fiyat',
        'BY_INVOICE_ID': 'Fatura Numarası',
        'BY_NAME': 'İsim',
        'CLEAR_FILTER': 'Filtreyi Temizle',
        'BY_RT_NUMBER': 'Rota Numarası',
        'BY_CITY': 'İl',
        'BY_DISTRICT': 'İlçe',
        'SEARCH_CONV': 'Konuşmalarda Ara',

        'CONTENT': 'Detaylar',
        'TITLE': 'Başlık',

        'ADDRESS_INFO': 'Adres Bilgileri',
        'ADDRESS': 'Adres',
        'TOTAL_ORDER': 'Toplam Kargo',
        'CUSTOMER_DBA': 'Marka Adı',
        'TAX_OFFICE': 'Vergi Dairesi',
        'TAX_NUMBER': 'Vergi Numarası',
        'AUTHORIZED_NAME': 'Yetkili Adı',
        'SEND_PUSH': 'Push Notification Gönder',
        'SEND_SMS': 'Sms Gönder',
        'SEND_MAIL': 'Mail Gönder',
        'SEND': 'Gönder',
        'DRAG_LOGO': 'Logoyu Sürükle',
        'DRAG_FILE': 'Dosyayı Sürükle',
        'SELECT_FILE': 'Dosya Seç',
        'OR': 'Yada',
        'SELECT_ALL': 'Hepsini Seç',
        'SELECT_TYPE': 'Tür Seç',
        'SELECT_WEHICLE': 'Araç Seç',
        'SELECT_STATIONS': 'Şubeleri Seçiniz',
        'PLEASE_SELECT': 'Seçim Yapınız',
        'SELECT': 'Seçim Yap',

        'PHONE_NUMBER': 'Telefon Numarası',
        'NUMBER_OF_PIECE': 'Parça Sayısı',
        'NEW_ORDER': 'Yeni Kargo',
        'NEW_ROUTE': 'Yeni Rota',
        'NEW_DRIVER': 'Yeni Kurye',
        'NEW_STATION': 'Yeni Şube',
        'TOTAL_RECORD': 'Toplam Kayıt',
        'TOTAL_PIECE_RECORD': 'Toplam Parça Sayısı',
        'BOVO_ID': 'Bovo ID',

        'PRINT': 'Yazdır',
        'SAVE_AND_PRINT': 'Kaydet ve Yazdır',
        'PRINT_PDF': 'Pdf olarak Yazdır',
        'EDIT': 'Düzenle',
        'NEW': 'Yeni Ekle',
        'UPDATE': 'Güncelle',
        'CANCEL': 'İptal',
        'ARE_U_SURE_DELETE': 'Silmek istediğinize emin misiniz ?',
        'DELETE': 'Sil',
        'REMEMBER': 'Hatırla',

        'OKEY': 'Tamam',
        'ACTION': 'İşlemler',
        'COMPANY_NAME': 'Firma Adı',
        'ADDRESS': 'Adres',
        'FILTER': 'Filtrele',
        'APPLY_FILTER': 'Filtreyi Uygula',
        'CLEAR_FILTER': 'Filtreyi Temizle',
        'EXCEL_EXPORT': 'Dışa Aktar',
        'COMPANY_TYPE': 'Firma Türü',
        'CURRENT_PAGE': 'Sayfa',
        'TOTAL_PAGE': 'Toplam Sayfa',
        'BACK': 'Geri',
        'PREV': 'İleri',
        'ADD': 'Ekle',
        'SAVE': 'Kaydet',
        'CREATE': 'Oluştur',
        'TRANSACTIONS': 'İşlemler',
        'CREATED_AT': 'Veri Giriş Tarihi',
        'DETAILS': 'Detaylar',
        'WARNING': 'Dikkat!',
        'CLOSE': 'Kapat',
        'CONTINUE': 'Devam Et',
        'WILL_YOU_CONTINUE': 'Devam etmek istiyor musunuz ?',

        'TODAY': 'Bugün',
        'YESTERDAY': 'Dün',
        'LAST_7_DAYS': 'Son 7 Gün',
        'LAST_30_DAYS': 'Son 30 Gün',
        'THIS_MONTH': 'Bu Ay',
        'LAST_MONTH': 'Geçen Ay',
        'CUSTOM_RANGE': 'Ozel Aralık',
        'DONE': 'Bitti',
        'FROM': 'dan',

        'TYPE': 'Tür',
        'DELIVERED_BY': 'Teslim Eden Kişi',
        'DELIVERED_AT': 'Teslim Tarihi',
        'DELIVERED_TO': 'Teslim Alan Kişi',
        'IDENTITY': 'Kimlik No',
        'NOT_DELIVERED_TO': 'Teslim Edilmeme Sebebi',
        'NOT_DELIVERED_AT': 'İşlem Tarihi',
        'SIGNATURE': 'İmza',
        'IMAGE': 'Resim',
        'BY': 'Tarafından',
        'ROUTE_ID': 'Rota ID',
        'EXPIRED_DATE': 'Bitiş Tarihi',
        'DATE': 'Tarih',
        'GO_TO_ORDER': 'Kargoya Git',
        'READ_ALL_PICKUP': 'Hepsini Teslim Alındı',
        'RECEIVER': 'Alıcı Müşteri',
        'TERM_TYPE': 'Arama Türü',
        'EARNING_NOT_FOUND': 'Odeme Bulunamadı',

        'DONT_ACCOUNT': 'Henüz hesabınız yok mu ?',
        'GET_START': 'Hemen Kayıt Ol!',
        'BRAND': '© 2023 Bovo.com.tr',

        'PRODUCT_ID': 'Ürün No',
        'SALE_COUNT': 'Satış Sayısı',
        'PRICE_COUNT': 'Fiyat Sayısı',
        'NO_PRODUCT': 'Ürün bulunamadı',
        'PRODUCT_PRICES': 'Fiyatlar',
        'PRICE': 'Fiyat',
        'CURRENCY': 'Para Birimi',
        'ADD_PRICE': 'Fiyat Ekle',
        'ADD_PRICE_INCREASE': 'Artan Fiyat Ekle',
        'REMOVE_PRICE': 'Fiyat Kaldır',
        'PRODUCT_SAVED': 'Ürün Eklendi',
        'PRODUCT_UPDATED': 'Ürün Güncellendi',
        'PRODUCT_DELETED': 'Ürün Silindi',
        'NAME': 'İsim',
        'DESCRIPTION': 'Açıklama',
        'CREATE': 'Kaydet',
        'PRICE_NOT_FOUND': 'Fiyat Bulunamadı',
        'PRODUCT_DETAIL': 'Ürün Detayları',
        'ID': 'No',
        'UNSELECT': 'Seçimi Kaldır',
        'MESSAGE': 'Mesaj',

        'QTY': 'Adet',
        'PAID': 'Odenen Tutar',
        'NOT_PAID': 'Bekleyen',
        'BALANCE_DUE': 'Kalan Tutar',
        'TOTAL_TIP': 'Toplam Bahşiş',
        'NEW_RETURN_ORDER': 'Yeni İade Kargo',
        'SHOW_ORDER': 'Kargoyu Göster',
        'TOTAL': 'Toplam',
        'INVOICE_DETAIL': 'Fatura Detayları',
        'INVOICE_ID': 'Fatura No',
        'NO_INVOICE': 'Fatura bulunamadı',
        'INVOICE_REPORT': 'Fatura Raporları',
        'MONEY_UNIT': '₺',
        'OPEN': 'Açık',
        'TAX': 'Vergi',
        'PRICE_RATE': 'Birim Başı Fiyat',
        'PAID_SUCCESS': 'Odendi',
        'ADD_PAYMENT': 'Odeme Ekle',
        'PAYMENTS': 'Odemeler',
        'DEBT': 'Borç',
        'CREDIT': 'Alacak',

        'WAREHOUSE': 'Çıkış/Toplama Noktası',
        'SELECT_WAREHOUSE': 'Depo Seç',
        'PICKUP_TYPE': 'Toplama Noktası',
        'FROM_WAREHOUSE': 'Depodan Çıkış',
        'FROM_CUSTOMER': 'Müşteriden Çıkış',
        'RETURN_ADDRESS_TYPE': 'İade Noktası',
        'PICKUP_LOCATION': 'Çıkış Deposu',
        'PICKUP_BY': 'Toplayan',
        'RETURN_WAREHOUSE': 'İade Deposu',
        'CUSTOMER_LOCATION': 'Müşteri Adresine İade',
        'INVOICE_ADDRESS': 'Fatura Adresi',
        'SAME_ADDRESS': 'Depo Adresini Kullan',
        'DIFFERENT_ADDRESS': 'Farklı Fatura Adresi Kullan',
        'MY_PROFILE': 'Kullanıcı Bilgilerim',
        'USER_INFO': 'Kullanıci Bilgileri',
        'FIRST_NAME': 'Adı',
        'LAST_NAME': 'Soyadı',
        'PASSWORD': 'Şifre',
        'PERMISSION': 'İzinler',
        'USERS': 'Kullanıcılar',
        
        'NOT_FOUND': 'Veri Bulunamadı',
        'ADD_NEW': 'Yeni Ekle',

        'ORDER_REPORT': 'Kargo Raporları',
        'EARNING_REPORT': 'Kurye Odeme Raporları',
        'NUMBER_OF_ROUTE': 'Rota Sayısı',

        'EARNING_DETAIL': 'Kurye Odeme Detayları',
        'EARNING_ID': 'Odeme No',

        'STARTED_AT': 'Başlama Zamanı',
        'ENDED_AT': 'Bitiş Zamanı',

        'SELECT_CUSTOMER': 'Müşteri Seç',
        'CUSTOMER_NAME': 'Müşteri Adı',
        'PRICE_PER_ORDER': 'Kargo Başı Fiyat',

        'MIN_WEIGHT': 'Min. Desi',
        'MAX_WEIGHT': 'Max. Desi',
        'MIN_KM': 'Min. KM',
        'MAX_KM': 'Max. KM',
        'PRICING': 'Fiyatlandırma',
        'ADD_PRICE': 'Fiyat Ekle',
        'REMOVE_PRICE': 'Kaldır',

        'WIDTH': 'En',
        'LENGTH': 'Boy',
        'HEIGHT': 'Yükseklik',
        'DESI': 'Desi',

        'START_KM': 'Başlangıç KM',
        'END_KM': 'Bitiş KM',

        'STATION_DISTRICT_INFO': 'Şubeye yetkili ilçeleri şeçiniz',
        'ADD_DISTRICT': 'İlçe Ekle',
        'DISTRICTS': 'İlçeler',
        'CODE': 'Kod',

        'STATION_CREATED': 'Şube oluşturuldu.',
        'STATION_UPDATED': 'Şube güncelledi.',
        'STATION_DELETED': 'Şube silindi.',

        'EXPORT_EXCEL': 'Excel\'e Aktar',
        'REPORTS': 'Raporlar',
        'REPORT': 'Rapor',
        'COMPANY_NAME': 'Firma Adı',
        'DBA_INFO': '* Turuncu alanda bulunan Marka adı SMS\'ler, Telefon numarasıda Destek Telefonu olarak kullanılacaktır.',
        'COMPANY_INFO_UPDATED': 'Firma bilgileriniz güncellenmiştir',

        'PROOF': 'Teslimat Bilgileri',

        'YES': 'Evet',
        'NO': 'Hayır',
        'DISPATCHER': 'Sevkiyat Yetkisi',
        'DISPATCH_STATIONS': 'Yekili Şubeler',
        'DEVICE': 'Cihaz',
        'DOCUMENTS': 'Dokümanlar',
        'WORKER_TARGET_REPORT': 'Kurye Hedef Rapor',

        'APPROVE': 'Onayla',
        'REMOVE_APPROVE': 'Onayı Kaldır',
        'SWITCH_ONLINE': 'Online Yap',
        'SWITCH_OFFLINE': 'Offline Yap',

        'WAIT_APPROVE': 'Onay Bekliyor',
        'APPROVED': 'Onaylandı',

        'DONE': 'Tamam',
        'PRICE_PER_ORDER': 'Kargo Başı Ücret',
        'NOTES': 'Notlar',
        'DELIVERY_PAYMENT_TYPE': 'Tahsilatlı Gönderi',
        'UA_PAYMENT_TYPE': 'Ücret Alıcı Gönderi',
        'DELIVERY_PAYMENT_PRICE': 'Tahsilat Tutarı',
        'DELIVERY_PAYMENT_PAY_TYPE': 'Tahsilat Türü',
        'CREDIT_CARD': 'Kredi Kartı',
        'CASH': 'Nakit',

        'CUSTOMER_TITLE': 'Ünvan',
        'CUSTOMER_ADDRES': 'Adres',
        'USE_UP': 'Yukarıdaki Bilgiyi Kullan',
        'EMAIL': 'E-Mail',
        'LOCATION_INVOICE': 'Fatura Bilgileri',

        'AUTHORITY_STATIONS': 'Yetkili Olduğu Şubeler',
        'COLLECTION': 'KO Tahsilat',
        'TOTAL_COLLECTION': 'Toplam KO',
        'KO': 'Odeme Durumu',
        'KO_TYPE': 'Odeme Türü',
        'KO_PRICE': 'Kapıda Odeme Tutarı',
        'KO_NO': 'Odendi',
        'WORK_TYPE': 'Kargo Türü',

        'NO_COLLECTION': 'Kapıdan Odeme Tahsilatı Bulunamadı',
        'PAYMENT_NOT_FOUND': 'Odeme Bulunamadı',
        'COLLECTION_REPORT': 'Kapıdan Odeme Raporları',

        'BARCODE': 'Barkod',
        'WORKER': 'Kurye',
        'ORDER_PRICE': 'Kargo Ücreti',
        'NO_NOTIFICATION': 'Not bulunamadı',

        'MOVEMENT_TYPE': 'Hareket Türü',

        'LOAD_VEHICLE': 'Müşteriden Teslim Al',
        'LOAD_LINE_VEHICLE': 'Hat Aracına Yükle',
        'SET_DOWN': 'Transfer Merkezine İndir',
        'LOAD_STATION': 'Şube Aracına Yükle',
        'DOWN_STATION': 'Şube Merkezine İndir',
        'GROUND_CARGO': 'Yerdeki Kargoyu Okut',

        'SIGNATURE_CODE': 'Mühür',

        'INSTALL_VEHICLE': 'Araca Yükle',
        'LOAD_STATION': 'Şube hattına Yükle',

        'CHECK_ORDER': 'Teyit okutması yap',
        'DOWNLOAD_VEHICLE': 'Araçtan İndir',
        'ORDER_ID_OR_SPECIAL': 'Kargo No veya Entegre Kod',

        'PAYMENT_INFO': 'Odeme Bilgileri',
        'ACCOUNT_HOLDER_NAME': 'Hesap Sahibi Adı',
        'IBAN': 'IBAN',
        'WORKER_TARGET': 'Kurye Hedef',

        'ARE_YOU_SURE_DELETE': 'Silmek istediğinize emin misiniz ?',

        'ONLINE_PAYMENT_ORDER': 'Kapıdan Odeme',
        'OFFLINE_PAYMENT_ORDER': 'Odendi',

        'FINANCE': 'Finans',
        'TAX_NUMBER': 'Vergi Numarası',
        'COMPANY_ADDRESS': 'Firma Adresi',
        'COMPANY_DOCUMENT': 'Vergi Levhası',

        'DAILY_ROUTE_COUNT': 'Günlük açılacak rota sayısı',
        'ROUTE_MOVE': 'Rota Kargo Ekleme',
        'OPEN_ROUTE': 'Açık Rotalar',
        'ROUTE': 'Rota',
        'SELECT_ROUTE': 'Rota Seç',
        'SPECIAL_TRACKING_NUMBER': 'Entegrasyon Kodu',
        '3RD_NUMBER': '3rd Kargo Kodu',
        'REFERANCE_NUMBER': 'Müşteri Referans Kodu',
        'WAYBILL_NUMBER': 'İrsaliye No',
        'PERSONAL_NUMBER': 'Gönderi Özel Alanı',
        'ORDER_SOURCE': 'Kargo Veri Kaynağı',
        'SMS_EMAIL_PERMISSION': 'Sms-Email İzni',
        'SEND_SMS': 'Sms Gönder',
        'SEND_EMAIL': 'E-Mail Gönder',
        'ALL_ORDERS': 'Entegrasyon Listesi',
        'ALL_REAL_ORDERS': 'Kargo Listesi',
        'BY_TERM': 'Kargo Ara',
        'CARGO_DATE': 'Kargo Trh.',
        'RETURN_DOOR_DATE': 'Kapıdan İade Randevu Trh.',
        'SELECT_DATE_TYPE': 'Tarih Türü',
        'CREATED_AT_TYPE': 'Kayıt Trh. ile',
        'CARGO_DATE_TYPE': 'Kargo Trh. ile',
        'COMPLETED_AT_TYPE': 'Teslim Trh. ile',
        'CITY_STATE': 'İl-İlçe',
        'INTEGRATION_NUMBER': 'Ent. No :',

        'TRANSFER_NOT_FOUND': 'Transfer Merkezi Bulunamadı',
        'TRANSFER_CREATED': 'Transfer Merkezi oluşturuldu.',
        'TRANSFER_UPDATED': 'Transfer Merkezi güncelledi.',
        'TRANSFER_DELETED': 'Transfer Merkezi silindi.',
        'BY_TRANSFER': 'Transfer Merkezi Ara',
        'NEW_TRANSFER': 'Yeni Ekle',
        'NEW_TRANSFER': 'Transfer Merkezi Ekle',
        'EDIT_TRANSFER': 'Transfer Merkezi Bilgileri Düzenle',
        'TRANSFER_CENTERS': 'Transfer Merkezleri',
        'SELECT_TRANSFER': 'Transfer Merkezi Seç',
        'TRANSFER': 'Transfer Merkezi',

        'BY_SHIPMENT_AREA': 'Dağıtım Bölgesi Ara',
        'NEW_SHIPMENT_AREA': 'Yeni Dağıtım Bölgesi',
        'EDIT_SHIPMENT_AREA': 'Dağıtım Bölgesi Düzenle',
        'SHIPMENT_AREA': 'Dağıtım Bölgeleri',
        'SHIPMENT_AREA_NOT_FOUND': 'Dağıtım Bölgesi Bulunamadı',
        'SHIPMENT_AREA_CREATED': 'Dağıtım Bölgesi oluşturuldu.',
        'SHIPMENT_AREA_UPDATED': 'Dağıtım Bölgesi güncelledi.',
        'SHIPMENT_AREA_DELETED': 'Dağıtım Bölgesi silindi.',

        'FAIL_ORDER': 'Teslim Edilemeyen Kargolar',
        'NO_FAIL_ORDER': 'Teslim Edilemeyen Kargo Listesi Bulunamadı',
        'FAIL_ORDER_SAVED': 'Kaydedildi',
        'FAIL_ORDER_UPDATED': 'Güncellendi',
        'FAIL_ORDER_DELETED': 'Silindi',
        'ORDER_COUNT': 'Kargo Sayısı',
        'PRINT_STATUS': 'Yazdırılma Durumu',
        'RETURN_LIST': 'Listeye Dön',
        'FAIL_ORDER_WARNING': 'Dikkat bu listede Teslim Edilemedi durumu dışında duruma sahip kargolar vardır. Devam etmek istiyor musunuz ? ',

        'TRANSFER_ORDER': 'Devreden Kargolar',
        'TRANSFER_DATE': 'Devir Tarihi',
        'TRANSFER_WORKER': 'Devir Kurye',
        'TRANSFER_PCS': 'Devir Kargo',

        'RETURN_ORDER': 'Alım Talepleri',
        'RETURN_ORDER_ID': 'Alım Talebi',
        'NO_RETURN_ORDER': 'Teslim Edilemeyen Kargo Listesi Bulunamadı',
        'RETURN_ORDER_SAVED': 'Kaydedildi',
        'RETURN_ORDER_UPDATED': 'Güncellendi',
        'RETURN_ORDER_DELETED': 'Silindi',
        'RETURN_ORDER_WARNING': 'Dikkat bu listede Teslim Edilemedi durumu dışında duruma sahip kargolar vardır. Devam etmek istiyor musunuz ? ',

        'WORKER_STATION': 'Kurye Şubesi',
        'WORKER_SUPPLIER': 'Kurye Tedarikçisi',
        'DOCUMENTS': 'Dokümanlar',
        'ADD_DOCUMENT': 'Doküman Ekle',
        'DOCUMENT_TYPE': 'Doküman Türü',
        'DOCUMENT_NOT_FOUND': 'Doküman Bulunamadı',
        'SHOW': 'Göster',

        'DELETE_ORDER_PERMISSION': 'Kargo Silme',
        'SHOW_ROUTE_PERMISSION': 'Rota Listesini Görüntüleme',
        'SHOW_CUSTOMER_PERMISSION': 'Müşteri Listesini Görüntüleme',
        'SHOW_ORDER_PERMISSION': 'Kargo Listesi Görüntüleme',
        'ADD_USER_PERMISSION': 'Kullanıcı Ekleme',
        'SHOW_FINANCE_PERMISSION': 'Finansal Raporlar',
        'SHOW_SETTING_PERMISSION': 'Ayarlar',
        'EXCEL_PERMISSION': 'Excele Aktarma',
        'CREATE_ORDER_PERMISSION': 'Kargo oluşturma',
        'CREATE_PRODUCT_PERMISSION': 'Ürün Ekle Kaldır',
        'COMPLETE_ROUTE_PERMISSION': 'Rota Tamamla',
        'DELETE_ROUTE_PERMISSION': 'Rota Sil',
        'ADD_WORKER_PERMISSION': 'Kurye ekleme',
        'ADD_CUSTOMER_PERMISSION': 'Müşteri Ekleme',

        'APP_SSTATUS': 'App Durumu',

        'PAUSE': 'Kapalı',
        'ONLINE': 'Online',
        'CLOSED': 'Kullanmıyor',

        'DASHBOARD': "Özet",
        'SUPPORT': 'Destek',
        'ORDER_SUPPORT': 'Kargo Destek Durumu',
        'RESOLVED': 'Çözüldü',
        'UNRESOLVED': 'İlgili Birime Verildi',
        'SUPPORT_STATUS': 'Destek Durumu',
        'LAST_LOG_DATE': 'Son Hareket Tarihi',
        'DATA_CREATED_DATE': 'Veri Tarihi',
        'GK_SUCCESS_RATE': 'Bovo Başarı Oranı',

        'POSTMAN_ID': 'KargoTürk Ent. No : ',
        'HEPSIJET_ID': 'Hepsijet Ent. No : ',
        'SENDEO_ID': 'Sendeo Ent. No : ',
        'PTT_ID': 'PTT Ent. No : ',
        'YURTICI_ID': 'YURTICI Ent. No : ',
        'KARGOIST_ID': 'Kargoist Ent. No : ',
        'UPS_ID': 'UPS Ent. No : ',
        'KARGOM_SENDE_ID': 'KargomSende Ent. No : ',
        'MNG_RETURN_ID': 'Mng Iade Ent. No : ',
        'GKN_KARGO_ID': 'GKN Ent. No : ',
        'SCOTTY_ID': 'Scotty Ent. No : ',
        'YANGO_ID': 'Yango Ent. No : ',
        'TALHA_NAKLIYAT_ID': 'Talha Nak. Ent. No : ',
        'ANKARA_KARGO_ID': 'Ankara Kargo Ent. No : ',
        'KOLAY_GELSIN_ID': 'Kolay Gelsin Ent. No : ',
        'KARGOTURK_ID': 'KargoTürk Ent. No : ',
        'BOLT_ID': 'Bolt Ent. No : ',
        'HEPSI_JET_ID': 'Hepsijet Ent. No : ',

        'SURAT_ID': 'Sürat Ent. No : ',
        'GEL_AL_ID': 'GelAl Ent. No : ',
        'MGN_TRACKING_NUMBER': 'Mng Tkp No: ',

        'SUMMARY': 'Özet',
        'PACKAGE_COUNT': 'Paket Sayısı',
        'TOTAL_WEIGHT': 'Toplam Desi',
        'TOTAL_PAY_ON_DOOR': 'Kap. Od. Kar.',
        'TOTAL_PAY_ON_DOOR_PRICE': 'Kap. Od. Tahs. Tut.',
        'TOTAL_PRICE': 'Toplam Tut.',
        'PRICE_WITH_TAX': 'Toplam Tut. + KDV',

        'PRICE_CALCULATION_TYPE': 'Fiyat Hesaplama Türü',
        'PER_PRICE': 'Parça Başı',
        'CUMULATIVE': 'Kümülatif',

        'IN_LINE_VEHICLE': 'Ring Aracında',
        'SELECT_LINE_VEHICLE': 'Ring Aracı Seç',
        'READ_LINE_VEHICLE': 'Ring Aracı Okut',

        'LINE_VEHICLE': 'Ring Aracı',
        'LINE_VEHICLES': 'Ring Araçları',
        'NEW_LINE_VEHICLE': 'Yeni Araç',
        'EDIT_LINE_VEHICLE': 'Araç Düzenle',
        'LINE_VEHICLE_NOT_FOUND': 'Araç Bulunamadı',
        'BY_LINE_VEHICLE': 'Araç Ara',

        'VEHICLE_KM': 'Araç KM',
        'CHASSIS_NUMBER': 'Şasi Numarası',
        'VEHICLE_OWNER': 'Araç Sahibi',
        'VEHICLE_UNIQUE_ID': 'Araç Barkodu (Unique)',

        'ROUTE_VEHICLE': 'Sefer Aracı',
        'ROUTE_VEHICLES': 'Sefer Araçları',

        'PLATE': 'Plaka',
        'DRIVER_NAME': 'Sürücü Adı',
        'IBAN_NUMBER': 'IBAN',
        'BANK_NUMBER': 'Banka',

        'VEHICLE_ROUTES': 'Güzergahlar',
        'NEW_VEHICLE_ROUTE': 'Yeni Güzergah Ekle',
        'EDIT_VEHICLE_ROUTE': 'Güzergah Düzenle',
        'VEHICLE_ROUTE_NOT_FOUND': 'Güzergah Bulunamadı',

        'VEHICLE_ROUTE_START_POINT': 'Çıkış Noktası Seç',
        'VEHICLE_ROUTE_END_POINT': 'Varış Noktası Seç',
        'VEHICLE_ROUTE_START': 'Çıkış Noktası',
        'VEHICLE_ROUTE_END': 'Varış Noktası',

        'SELECT_VEHICLE_ROUTE': 'Güzergah Seç',
        'ADD_VEHICLE_ROUTE': 'Yeni Güzergah Ekle',
        'REMOVE_VEHICLE_ROUTE': 'Güzergah Kaldır',

        'BY_VEHICLE_ROUTE': 'Güzergah Ara',

        'CARGO_DEBIT_LIST': 'Kargo Sorumluluk Listesi',

        'LAODED_ORDER_COUNT': 'Hat Aracına Yüklü Kargo Sayısı ',
        'DOWN_BATCH': 'Aracı Toplu İndir',
        'DOWN_BATCH_COMPLETED': 'Toplu İndirme Yapıldı',
        'ASK_DOWN_BATCH': 'Bu araçtaki tüm kargolar varış noktanıza toplu olarak indirilecektir, onaylıyor musunuz ?',

        'TRANSFER_REASON': 'Devir Sebepleri',
        'REDIRECT_REASON': 'Yönlendirme Sebepleri',
        'COMPLETED_REASON': 'Teslim Seçenekleri',
        'TRANSFER_REASON_': 'Devir Sebebi',
        'REDIRECT_REASON_': 'Yönlendirme Sebebi',
        'REASON_FOR_CHANGE': 'Değişim Sebebi',
        'CANCEL_REASON': 'İptal Sebebi',

        'NEW_REASON': 'Yeni Ekle',
        'EDIT_REASON': 'Düzenle',
        'REASON_NOT_FOUND': 'Veri Bulunamadı',
        'PERFORMANCE_EFFECT': 'Performans Etkisi',
        'PERFORMANCE_R_TYPE': 'Kimden Kaynaklı',
        'REASON_NAME': 'Tanım',
        'REASON_SHORT': 'Kısaltma',
        'ORDER_STATUSES': 'Kargo Durumları',
        'BY_REASON': 'Kelime ile ara',
        'CUSTOMER_TEXT': "Müşterinin Gördüğü Alan",
        'FIRST_ORDER_UPDATE': "İlk Kargo Okutma Saati",

        'NEGATIVE': 'Negatif',
        'POSITIVE': 'Etkisiz',

        'VOYAGES': 'Seferler',
        'VOYAGE_NOT_FOUND': 'Sefer Bulunamadı',
        'NEW_VOYAGE': 'Yeni Sefer',
        'EDIT_VOYAGE': 'Sefer Düzenle',
        'VOYAGE_TYPE': 'Sefer Türü',
        'VOYAGE_EXPENSE': 'Sefer Maliyeti',
        'VOYAGE_STATUS': 'Sefer Durumu',
        'RING': 'Ring Aracı',
        'CREATE_AND_BARCODE': 'Kaydet ve Okutmaya Geç',
        'CREATED_USER': 'Oluşturan Kullanıcı',

        'SAVE_AND_SEND_VEHICLE': 'Kaydet ve Aracı Gönder',
        'VOYAGE_ON_WAY': 'Yolda',

        'VOYAGE_MOVEMENT_TYPE': 'Hareket Türü',
        'VOYAGE_UP': 'Yükleme',
        'VOYAGE_DOWN': 'İndirme',
        'READED_ORDERS': 'Okutulan Kargolar',
        'VOYAGE_DETAIL': 'Sefer Detayları',
        'READ_ORDER': 'Kargo Okut',
        'COMPLETE_VOYAGE': 'Seferi Kapat',
        'READ_GROUP_BARCODE': 'Grup Barkodu',
        'CREATE_GROUP_BARCODE': 'Grup Barkodu Yazdır',

        'AREA_MANAGER': 'Bölge Müdürlükleri',
        'AREA_MANAGEMENT_NOT_FOUND': 'Bölge müdürlüğü bulunamadı',

        'NEW_AREA_MANAGEMENT': 'Yeni Bölge Müdürlüğü',
        'EDIT_AREA_MANAGEMENT': 'Bölge Müdürlüğü Düzenle',
        'BY_AREA_MANAGEMENT': 'Bölge Müdürlüğü Ara',

        'MAIN_BRANCH': 'Ana Birim',
        'SELECT_MAIN_BRANCH': 'Ana Birim Seç',
        'DEALER': 'Tedarikçi',

        'DRIVER_VEHICLES': 'Kuryeler  Kullanıcılar  Araçlar',

        'UP_ORDERS': 'Araçta Olan Kargolar',
        'DOWN_ORDERS': 'İndirilen Kargolar',
        'NOT_READED_ORDERS': 'İndirilen Kargolar',
        'BATCH_UP': 'Toplu Yükleme Yap',
        'BATCH_DOWN': 'Toplu İndirme Yap',

        'READ_ORDER_UP': 'Kargo Yükle',
        'READ_ORDER_REMOVE': 'Kargo Çıkar',
        'READ_ORDER_DOWN': 'Kargo İndir',

        'VOYAGE_COMPLETED': 'Kapatıldı',
        'VOYAGE_NO': 'Sefer No',

        'ESTIMATED_DELIVERY_HOUR': 'Tahmini Teslimat Süresi (Saat)',
        'ESTIMATED_DELIVERY_DATE': 'Tahmini Teslimat Tarihi',
        'THIRD_PARTY_ESTIMATED_DELIVERY_DATE': 'Third Party Tahmini Teslimat Tarihi',
        'PERFORMANCE_TYPE': 'Rapor Tipi',

        'PRINT_RETURN_BARCODE': 'İade Barkodu Bas',
        'PRINT_RETURN_COMPLETE_BARCODE': 'İade Teslim Barkodu Bas',

        'DEFINITIONS': 'Tanımlar',
        'M_C': 'Mücbir Sebep',
        'CUSTOMER_KO_PRICE': 'Kapıda Odeme Ücreti',

        'HOLIDAYS': 'Tatil Günleri',
        'PACKAGE_TYPE': 'Paket Türü',
        'DECISION_LIST': 'Karar Listesi',
        'OFFZONE_LIST': 'AT Listesi',

        'PRINT_DELIVERY_RECEIPT': 'Teslim Tesellüm Belgesi Yazdır',

        'PALLETTES': 'Paletler',

        'NO_DATA': 'Veri Bulunamadı',

        'PALLETTE_NUMBER': 'Palet No',
        'IS_CLOSED': 'Kapanma Durumu',
        'CLOSED_AT': 'Kapanma Zamanı',
        'BY_TERM_PALLETTE': 'Palet Ara',
        'PALLETTE_DETAIL': 'Palet Detayları',
        'DEBIT_DATE': 'Zimmet Tarihi',
        'PALLET_ADD_DATE': 'Palete Eklenme Tarihi',
        'PALLETTE_READ_STATUS': 'Palletten Okutma Durumu',

        'SELECT_INTEGRATION': 'Entegrasyon Seç',
        'INTEGRATION': 'Entegrasyon',

        'SELECT_PRICE_LIST': 'Fiyat Listesi Seç',
        'IS_KM': 'Mesafe',
        'IS_MIN_PRICE': 'Min Fiyat',
        'PRICE_LIST': 'Fiyat Listesi',
        'PRICE_ASSIGN': 'Fiyat Listesi Ataması',
        'SELECT_PRICING_TABLE': 'Fiyat Tablosu Seç',
        'NEW_PRICE_LIST': 'Yeni Fiyat Listesi',
        'EDIT_PRICE_LIST': 'Fiyat Listesi Düzenle',
        'PRICE_LIST_NOT_FOUND': 'Fiyat Listesi Bulunamadı',
        'RETURN_RATE': 'İade Oranı',
        'SMS_PRICE': 'Sms Ücreti',
        'SECOND_DELIVERY_FEE': '2. Teslimat Ücreti',
        'STATUS': 'Durum',
        'ACTIVE': 'Aktif',
        'PASSIVE': 'Pasif',
        'START_DATE': 'Başlangıç Tarihi',
        'END_DATE': 'Bitiş Tarihi',
        'DEFAULT_PRICE_LIST': 'Bovo Fiyat Listesi',
        'BY_ROUTE_STATUS': 'Rota Durumu',

        'ALLOCATION': 'Allokasyon Listesi',
        'ALLOCATION_NAME': 'Allokasyon Listesi Adı',
        'NEW_ALLOCATION': 'Yeni Allokasyon Listesi',
        'EDIT_ALLOCATION': 'Allokasyon Listesi Düzenle',
        'ALLOCATION_NOT_FOUND': 'Allokasyon Listesi Bulunamadı',

        'NEW_ALLOCATION_ASSIGN': 'Yeni Allokasyon Ataması',
        'EDIT_ALLOCATION_ASSIGN': 'Allokasyon Ataması Düzenle',
        'ALLOCATION_ASSIGN_NOT_FOUND': 'Allokasyon Ataması Bulunamadı',
        'SELECT_ALLOCATION': 'Allokasyon Listesi Seç',
        'ALLOCATION_ASSIGN': 'Allokasyon Ataması',
        'REPORT_DATE_TYPE': 'Tarih Türü',
        'REPORT_ORDER_TYPE': 'Rapor Türü',
        'CUTOFF_DATE': 'Cutoff Tarihi',
        'OPEN_ORDERS': 'Açık Kargolar',
        'COMPLETED_ORDERS': 'Tamamlanan Kargolar',
        'ALL_ORDERS_TYPE': 'Tüm Kargolar',
        'REPORT_BY_STATUS': 'Duruma Göre',

        // Status
        'STATUS_DELIVERED': 'Teslim Edildi',
        'STATUS_TRANSFER': 'Devir',
        'STATUS_IN_DISTRIBUTION': 'Dağıtımda',
        'STATUS_TRANSFER_CENTER': 'Transfer Merkezinde',
        'STATUS_STATIONS': 'Şubede',
        'STATUS_RING': 'Ring Aracında',
        'STATUS_RETURN_DELIVERY': 'İade Teslim Edildi',
        'STATUS_CARGO_ACCEPT': 'Kargo Kabul',
        'STATUS_RETURN_START': 'İade Süreci Başladı',
        'STATUS_ORIENTATION': 'Yönlendirme',
        'STATUS_PARTİAL_DELIVERY': 'Kısmi Teslimat',
        'STATUS_PROBLEM': 'Hasarlı',
        'STATUS_LOSS': 'Kayıp',
        'MARKETPLACE': 'Pazaryeri',
        'SHIPMENTHUB_MARKETPLACE': 'Yurtdışı Pazaryeri',
        'SHIPMENTHUB_CARGO': 'Yurtdışı Kargo',
        'MARKETPLACE_CODE': 'Pazaryeri Kodu',
        'OUT_OF_QUOTA': 'Kota Dışı',
        'DISTRICT': 'İlçe',
        'CANCEL_COMPENSATE': 'Tazmin Sürecini İptal Et',

        'ALLOCATION_DISTRIBUTION': 'Desi Dağıtım Listesi',
        'SELECT_ALLOCATION_DISTRIBUTION': 'Desi Dağıtım Listesi Seç',
    });

    $translateProvider.translations('en', {
        // login.html
        'LOGIN_TITLE': 'Giriş Yap',
        'EMAIL_LOGIN_PLCHLDR': 'E-Posta Adresiniz...',
        'PASS_LOGIN_PLCHLDR': 'Şifreniz...',
        'REMEMBER_ME': 'Beni Hatırla',
        'LOGIN': 'Giriş Yap',

        // forgot.html
        'FORGOT_PASS': 'Forgot my password',
        'PASS_RESET_TITLE': 'Forgot my password',
        'PASS_RESET_CONTENT': 'Please enter the email address you\'d like your password reset information sent to.',
        'RESET_PASS_BTN': 'Reset my password',

        // password.html
        'PASSWORD_TITLE': 'Yeni Şifre',
        'PASS_REPEAT_PLCHLDR': 'Şifre Tekrar...',
        'PASS_FORM_BTN': 'Sıfırla',


        'MAP': 'Map',
        'ROUTES': 'Routes',
        'ORDERS': 'Orders',
        'OFFERS': 'Offers',
        'SUPPORT': 'Support',
        'ORDER_WARNINGS': 'Uyarılar',
        'DRIVERS': 'Drivers',
        'EARNING': 'Earning',
        'PARTNERS': 'Partners',
        'INVOICES': 'Invoices',
        'STATIONS': 'Stations',
        'REPORTS': 'Reports',
        'SETTINGS': 'Settings',
        'LOGOUT': 'Logout',
        'ACCOUNT_INCORRECT_INFO': 'Your information is incorrect Check and try again',

        // Filters
        'BY_STATION': 'By Station',
        'BY_DRIVER': 'By Driver',
        'BY_CUSTOMER': 'By Customer',
        'BY_PARTNER': 'By Partner',
        'BY_ORDER_ID': 'By Order ID',
        'BY_DELIVERY_DATE': 'By Delivery Date',
        'BY_STATUS': 'By Status',
        'BY_TYPE': 'By Type',
        'BY_QR_CODE': 'By Qr Code',



    });

    //$translateProvider.determinePreferredLanguage();
    $translateProvider.preferredLanguage(LANGUAGE);
    $translateProvider.useSanitizeValueStrategy('sce');
}]);