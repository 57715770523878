bovoApp.controller('MovementController', ['$scope', 'OrderMovement', '$location', '$filter', '$routeParams', '$rootScope', 'ngAudio',
	function ($scope, OrderMovement, $location, $filter, $routeParams, $rootScope, ngAudio) {

		$scope.currentRoute = $location.path();
		var successSound = ngAudio.load("sounds/success.mp3");
		var errorSound = ngAudio.load("sounds/error.mp3");
		$scope.filterData = {};
		$scope.getDebitList = function () {
			OrderMovement.getDebitList($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.bulkTransactions = [
			{value: 'complete', label: 'Teslim Et'},
			{value: 'delete', label: 'Sil'},
			{value: 'return_delete', label: 'Silinen Kargoyu Getir'},
			{value: 'cancelled', label: 'İptal Et'},
			{value: 'refund', label: 'İade teslim edildi'},
			{value: 'change_type', label: 'Hareketsiz Kargo Türü Değiştir'},
			{value: 'change_edd', label: 'Tahmini Teslim Tarihi Güncelle'},
			{value: 'hj_recall', label: 'Hepsijet Recall'},
		];

		$scope.$on('refresh_data', function() {
			console.log('refreshing debit lsit...')
			$scope.getDebitList();
		}, true);

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
				}
				$scope.filterProp = [];
			} 
			$scope.getDebitList();
		}

		$scope.send = function () {
			OrderMovement.movement($scope.movement).success(function (data, status, headers, config) {
				$scope.movement.code = null
				$scope.orders = data.batch.orders;
				$scope.batch = data.batch;
				$scope.movement.force = false;
				Messenger().post(data.message);
				successSound.play()
			}).error(function (data) {
				errorSound.play()
				if (data.type && data.type === 'wrong_station') {
					$scope.errorMessage = data.message
					$('#wrongStation').modal('show');
					setTimeout(() => {
						document.getElementById("wrongStation").focus();
					}, 300);
				} else {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				}

			});
		};

		$scope.bulkComplete = function () {
			OrderMovement.bulkComplete($scope.complete).success(function (data, status, headers, config) {
				$scope.complete = {
					type: '',
					receiver: null,
					orders: [],
					proccess: $scope.currentRoute == '/order-bulk-complete' ? 'complete' : 'hj_recall',
					find_type: 'id',
					work_type: null
				}
				Messenger().post(data.message);
				successSound.play()
			}).error(function (data) {
				if (data.type && data.type === 'batch_voyage') {
					successSound.play()
					$scope.errorMessage = data.message
					$('#wrongStation').modal('show');
					setTimeout(() => {
						document.getElementById("wrongStation").focus();
					}, 300);
				}else if (data.type && data.type === 'wrong_station') {
					errorSound.play()
					$scope.errorMessage = data.message
					$('#wrongStation').modal('show');
					setTimeout(() => {
						document.getElementById("wrongStation").focus();
					}, 300);
				} else {
					errorSound.play()
					Messenger().post({
						message: data.message,
						type: "error"
					});
				}

			});
		};

		$scope.continue = function () {
			$scope.movement.force = true;
			$('#wrongStation').modal('hide');
			$scope.send();
		}

		$scope.openDetail = function (id) {
			window.open('/orders?batch_order_id=' + id, '_blank');
		}

		$scope.getBatch = function () {
			OrderMovement.getBatch($scope.movement).success(function (data, status, headers, config) {
				$scope.orders = data.batch.orders;
				$scope.batch = data.batch;
				$scope.oldBatch = data.oldBatch;
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.findLineVehicle = function () {
			OrderMovement.findLineVehicle($scope.movement).success(function (data, status, headers, config) {
				$scope.lineVehicle = data.lineVehicle;
				$scope.line_vehicle_id = data.lineVehicle.id;
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.print = function () {
			window.open('/print-batch/' + $scope.batch.id, '_blank').focus();
		};

		$scope.saveAndPrint = function () {
			var data = $scope.movement;
			data.save = true;
			OrderMovement.getBatch(data).success(function (data, status, headers, config) {
				$scope.orders = data.batch.orders;
				$scope.batch = data.batch;
				window.open('/print-batch/' + $scope.batch.id, '_blank').focus();
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.init = function () {
			OrderMovement.movementInit().success(function (data, status, headers, config) {
				$scope.data = data;
				$scope.movement = {
					type: '',
					code: null,
					station_id: null,
					line_vehicle_id: null,
					weight: null
				}
				$scope.complete = {
					proccess: $scope.currentRoute == '/order-bulk-complete' ? 'complete' : 'hj_recall',
					type: '',
					receiver: null,
					orders: [],
					find_type: 'id'
				}
				if($scope.authenticatedUser.role == 3 && data.permissions.indexOf($scope.authenticatedUser.email) == -1) {	
					$location.path('customer-orders');
				}
			});
		};

		$scope.downBatch = function (batch) {
			$('#askDownBatch').modal('hide');
			var data = {
				batch_id: $scope.oldBatch.id
			}
			OrderMovement.downBatch(data).success(function (data, status, headers, config) {
				Messenger().post(data.message);
				successSound.play()
				$scope.getBatch();
			});
		};
	}
]);