bovoApp.controller('ThirdPartyDecisionController', ['$scope', 'Order', '$location', '$filter', '$routeParams', '$rootScope',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope) {

		$scope.filterProp = [];
		$scope.selectedItem = [];
		$scope.permitteds = [];
		$scope.calendarView = 'month';

		$scope.addSelectedItem = function (item) {

			if ($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			} else {
				$scope.selectedItem.push(item);
			}
		}

		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: moment().subtract(29, 'days'),
				endDate: moment()
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.addFilterProps = function (item) {
			if ($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if (item != 'term') {
					$scope.filterData[item] = [];
				} else {
					$scope.filterData[item] = "";
				}
				if (item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if ($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
				$scope.filterProp = [];
			} else {
				$scope.filterData.filterProp = $scope.filterProp;
				if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.init();
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing orderdata...')
			$scope.init();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.init();
		}

		$scope.cargoFirms = [
			{ id: 'KARGOMSENDE', value: 'KARGOMSENDE' },
			{ id: 'KOLAYGELSIN', value: 'KOLAYGELSIN' },
			{ id: 'DHL', value: 'DHL' },
			{ id: 'DHL_UK_RETURN', value: 'DHL_UK_RETURN' },
			{ id: 'DHL_EU_RETURN', value: 'DHL_EU_RETURN' },
			{ id: 'KARGOTURK', value: 'KARGOTURK' },
			{ id: 'HEPSIJET', value: 'HEPSIJET' },
			{ id: 'SENDEO', value: 'SENDEO' },
			{ id: 'PTT', value: 'PTT' },
			{ id: 'YURTICI', value: 'YURTICI' },
			{ id: 'KARGOIST', value: 'KARGOIST' },
			{ id: 'UPS', value: 'UPS' },
			{ id: 'MNG', value: 'MNG' },
			{ id: 'SURAT', value: 'SURAT' },
			{ id: 'GKN', value: 'GKN' },
			{ id: 'SCOTTY', value: 'SCOTTY' },
			{ id: 'BOLT', value: 'BOLT' },
			{ id: 'YANGO', value: 'YANGO' },
			{ id: 'BOVO', value: 'BOVO' },
			{ id: 'HERODISPATCHER', value: 'HERODISPATCHER' },
		];

		$scope.init = function () {
			if ($routeParams.type) {
				$scope.filterData.type = $routeParams.type;
			}
			Order.thirdPartyDecisions($scope.filterData).success(function (data, status, headers, config) {
				console.log(data);
				$scope.data = data;
				$scope.permitteds = data.permissions;
				$scope.formatters = {};
				$scope.selectedItems = [];
				$rootScope.gridOptionsComplex = {
					enableFiltering: true,
					showGridFooter: true,
					showColumnFooter: true,
					paginationPageSizes: [500, 1000, 2000],
					paginationPageSize: 500,
					enableGridMenu: true,
					enableSelectAll: true,
					exporterCsvFilename: 'KararListesi.csv',
					exporterPdfDefaultStyle: { fontSize: 9 },
					exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
					exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
					exporterPdfHeader: { text: "My Header", style: 'headerStyle' },
					exporterPdfFooter: function (currentPage, pageCount) {
						return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
					},
					exporterPdfCustomFormatter: function (docDefinition) {
						docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
						docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
						return docDefinition;
					},
					exporterPdfOrientation: 'portrait',
					exporterPdfPageSize: 'LETTER',
					exporterPdfMaxGridWidth: 500,
					exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
					exporterExcelFilename: 'Fatura.xlsx',
					exporterExcelSheetName: 'Sheet1',
					exporterExcelCustomFormatters: function (grid, workbook, docDefinition) {
						var stylesheet = workbook.getStyleSheet();
						var stdStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri'
						});
						var boldStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri', bold: true
						});
						var aFormatDefn = {
							"font": boldStyle.id,
							"alignment": { "wrapText": true }
						};
						var formatter = stylesheet.createFormat(aFormatDefn);
						$scope.formatters['bold'] = formatter;
						var dateFormatter = stylesheet.createSimpleFormatter('date');
						$scope.formatters['date'] = dateFormatter;
						aFormatDefn = {
							"font": stdStyle.id,
							"fill": { "type": "pattern", "patternType": "solid", "fgColor": "FFFFC7CE" },
							"alignment": { "wrapText": true }
						};
						var singleDefn = {
							font: stdStyle.id,
							format: '#,##0.0'
						};
						formatter = stylesheet.createFormat(aFormatDefn);
						$scope.formatters['red'] = formatter;
						Object.assign(docDefinition.styles, $scope.formatters);
						return docDefinition;
					},
					exporterColumnScaleFactor: 4.5,
					exporterFieldApplyFilters: true,
					columnDefs: [
						{ name: 'ID', field: 'decision_id' },
						{ name: 'Plaka', field: 'plate' },
						{ name: 'İl', field: 'city' },
						{ name: 'İlçe', field: 'district' },
						{
							name: 'Firma 1', field: 'company_1',
							editableCellTemplate: 'ui-grid/dropdownEditor',
							editDropdownValueLabel: 'value',
							editDropdownOptionsArray: $scope.cargoFirms
						},
						{ name: 'Kapasite 1', field: 'capacity_1', type: 'number' },
						{
							name: 'Firma 2', field: 'company_2',
							editableCellTemplate: 'ui-grid/dropdownEditor',
							editDropdownValueLabel: 'value',
							editDropdownOptionsArray: $scope.cargoFirms
						},
						{ name: 'Kapasite 2', field: 'capacity_2', type: 'number' },
						{
							name: 'Firma 3', field: 'company_3',
							editableCellTemplate: 'ui-grid/dropdownEditor',
							editDropdownValueLabel: 'value',
							editDropdownOptionsArray: $scope.cargoFirms
						},
						{ name: 'Kapasite 3', field: 'capacity_3', type: 'number' },
						{
							name: 'Firma 4', field: 'company_4',
							editableCellTemplate: 'ui-grid/dropdownEditor',
							editDropdownValueLabel: 'value',
							editDropdownOptionsArray: $scope.cargoFirms
						},
						{ name: 'Kapasite 4', field: 'capacity_4', type: 'number' },
						// { name: 'Oluşuran', field: 'full_name' }
					],
					data: data.data
				};

				$rootScope.gridOptionsComplex.onRegisterApi = function (gridApi) {
					$scope.gridApi = gridApi;
					gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
						Order.updateThirdPartyDecisions(rowEntity).success(function (data, status, headers, config) { });
					});

					gridApi.selection.on.rowSelectionChanged($scope, function (row) {
						if (row.isSelected) {
							$scope.selectedItems.push(row.entity.id)
						} else {
							var index = $scope.selectedItems.indexOf(row.entity.id);
							if (index > -1) {
								$scope.selectedItems.splice(index, 1);
							}
						}
					});

					gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
						rows.forEach(element => {
							if (element.isSelected) {
								$scope.selectedItems.push(element.entity.id)
							} else {
								var index = $scope.selectedItems.indexOf(element.entity.id);
								if (index > -1) {
									$scope.selectedItems.splice(index, 1);
								}
							}
						});
					});
				};
			});
		};

		$scope.addItem = function () {
			$scope.data.decision_groups.push({
				customer_group_id: null,
			});
		}

		$scope.removeItem = function (index) {
			$scope.data.decision_groups.splice(index, 1);
		}

		$scope.save = function () {
			Order.saveThirdPartyDecisions($scope.data.decision_groups).success(function (data, status, headers, config) {
				Messenger().post('Kaydedildi');
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		}
	}
]);